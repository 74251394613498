import { AbonnementType, DocumentType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../containers/Espace/props';
import Item from './index';

const ListItemAbonnement: FC<ListItemProps<AbonnementType>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<AbonnementType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <Item doc={doc} index={index} onClick={onClick}>
      <div className="flex space-x-6 items-center">
        <strong className="name">{doc.datetime / 100}€</strong>
        <span className="bg-light-500 px-2 py-1 rounded">{doc.boutique}</span>
      </div>
    </Item>
  );
};

export default ListItemAbonnement;
